<template>
  <button
    class="bitts-pill"
    :class="{
      error: props.error,
      active: props.active,
      [props.type]: true,
    }"
    @click="(e) => emit('click', e)"
    type="button"
  >
    <BittsAvatar
      v-if="props.org || props.user"
      v-bind="dynamicAvatarProps()"
      size="x-small"
      class="mr-4"
    />
    <span>{{ textBuiltByProps }}</span>
    <slot name="pillButton" :user="props.user" :org="props.org">
      <div class="bitts-pill__button">
        <FontAwesomeIcon
          :icon="props.icon || pillIcon"
          :style="{ height: '8px', width: '8px', color: 'currentColor' }"
          class="currentColor"
        />
      </div>
    </slot>
  </button>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import { TOrganization, TUser } from '../BittsAvatar/types';

export type Props = {
  text?: string;
  error?: boolean;
  active?: boolean;
  org?: TOrganization | null;
  user?: TUser | null;
  icon?: string[] | null;
  type?: string;
};

const props = withDefaults(defineProps<Props>(), {
  text: '',
  error: false,
  active: false,
  org: null,
  user: null,
  icon: null,
  type: 'field',
});

const emit = defineEmits(['click']);

onMounted(() => {
  if (props.user && props.org) {
    console.warn(
      'BittsPill should have either user OR org, you have supplied both',
    );
  }
  if ((props.user || props.org) && props.text) {
    console.warn(
      'You do not need to provide text prop if using org or user. It will fill based on org name or user name',
    );
  }
});

const pillIcon = computed(() =>
  props.type === 'field' ? ['fak', 'x'] : ['fak', 'chevron-down'],
);

const textBuiltByProps = computed(() => {
  if (props.org && !props.user) return props.org.name;
  else if (props.user && !props.org) {
    if (props.user.last_name)
      return `${props.user.first_name} ${props.user.last_name}`;
    return props.user.first_name;
  }
  return props.text;
});

function dynamicAvatarProps() {
  const result: {
    org?: TOrganization | null;
    user?: TUser | null;
  } = {};
  if (props.org && !props.user) result.org = props.org;
  if (props.user && !props.org) result.user = props.user;
  return result;
}
</script>
<style scoped lang="pcss">
.bitts-pill {
  @apply border border-neutral-border/0 px-8 bg-neutral-background inline-flex rounded-4 items-center text-neutral-text text-base transition-colors;
  line-height: 22px;

  &:hover {
    @apply border border-neutral-border;
  }
}

.bitts-pill:focus-visible {
  @apply outline-none outline-offset-0 outline-2 outline-neutral-border-focus;
}

.bitts-pill__button {
  @apply flex items-center ml-8;
}

/* filter type */
.bitts-pill.filter {
  @apply bg-info-background-weak;

  &:hover {
    @apply border-info-border;
  }
}

.bitts-pill.filter.active {
  @apply bg-white border-info-border;

  &:focus-visible {
    @apply border-info-border/0;
  }
}

/* error type */
.bitts-pill.error:not([disabled]) {
  @apply bg-danger-background-weak text-danger-text;

  .bitts-pill__button {
    @apply text-danger-text-button;
  }

  &:hover {
    @apply border-danger-border-focus;
  }
}

/* disabled */
.bitts-pill[disabled] {
  @apply opacity-100 bg-neutral-background-disabled !cursor-not-allowed;

  .bitts-pill__button {
    @apply cursor-not-allowed pointer-events-none;
  }

  &:hover {
    @apply border-neutral-background-disabled/0;
  }
}
</style>
